(function () {
    'use strict';
    function initialize() {
        var input = document.getElementById('image-upload-location');

        (function pacSelectFirst(input) {
          // store the original event binding function
          var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

          function addEventListenerWrapper(type, listener) {
              // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
              // and then trigger the original listener.
              if (type == "keydown") {
                  var orig_listener = listener;
                  listener = function(event) {
                      var suggestion_selected = $(".pac-item-selected").length > 0;
                      if (event.which == 13 && !suggestion_selected) {
                          var simulated_downarrow = $.Event("keydown", {
                              keyCode: 40,
                              which: 40
                          });
                          orig_listener.apply(input, [simulated_downarrow]);
                      }

                      orig_listener.apply(input, [event]);
                  };
              }

              _addEventListener.apply(input, [type, listener]);
          }

          input.addEventListener = addEventListenerWrapper;
          input.attachEvent = addEventListenerWrapper;

          var autocomplete = new google.maps.places.Autocomplete(input);

        autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();

            var city = $('#image-upload-location').val();
            var country = '';
            var lat = 0;
            var lng = 0;
            if (place.address_components.length > 0) {
                city = place.address_components[0].long_name;
                country = place.address_components[place.address_components.length-1].long_name;
            }
            if (place.geometry.location) {
                lat = place.geometry.location.lat();
                lng = place.geometry.location.lng();
            }

            $('#image-upload-location-city').val(city);
            $('#image-upload-location-country').val(country);
            $('#image-upload-location-lat').val(lat);
            $('#image-upload-location-lng').val(lng);
        });
      })(input);
    }
    //// Ensuring that only Google Maps adresses are inputted
    function selectFirstAddress (input) {
        google.maps.event.trigger(input, 'keydown', {keyCode:40});
        google.maps.event.trigger(input, 'keydown', {keyCode:13});
    }

    // Select first address on focusout
    $('.view-upload').on('focusout', 'image-upload-location', function() {
        selectFirstAddress(this);
    });

    // Select first address on enter in input
    $('.view-upload').on('keydown', 'image-upload-location', function(e) {
        if (e.keyCode == 13) {
            selectFirstAddress(this);
        }
    });


    if($('body').is('.view-upload')){
            $(document).ready(function () {
               google.maps.event.addDomListener(window, 'load', initialize);
            });
    }
    $('#openCopyrightOverlay').on('click', function(e) {
        $("#uploadCopyrightOverlay").fadeToggle("fast");
    });
    $('#openPolicyOverlay').on('click', function(e) {
        $("#uploadPolicyOverlay").fadeToggle("fast");
    });
    $('#openImprintOverlay').on('click', function(e) {
        $("#uploadImprintOverlay").fadeToggle("fast");
    });
    $('.closeOverlayBtn').each(function(e) {
        $(this).on('click', function(event) {
            $(this).parent().hide();
        });
    });

    if($('body').is('.upload-step-1')){
        $('#gallery-upload, #camera-upload').on('change', function(e) {
            $(this).closest('form').submit();
        });
    }

})();
