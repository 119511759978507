(function () {
    'use strict';
    if($('body').is('.view-map')){
      var pac_input = document.getElementById('searchInput');

      (function pacSelectFirst(input) {
          // store the original event binding function
          var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

          function addEventListenerWrapper(type, listener) {
              // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
              // and then trigger the original listener.
              if (type == "keydown") {
                  var orig_listener = listener;
                  listener = function(event) {
                      var suggestion_selected = $(".pac-item-selected").length > 0;
                      if (event.which == 13 && !suggestion_selected) {
                          var simulated_downarrow = $.Event("keydown", {
                              keyCode: 40,
                              which: 40
                          });
                          orig_listener.apply(input, [simulated_downarrow]);
                      }

                      orig_listener.apply(input, [event]);
                  };
              }

              _addEventListener.apply(input, [type, listener]);
          }

          input.addEventListener = addEventListenerWrapper;
          input.attachEvent = addEventListenerWrapper;

          var autocomplete = new google.maps.places.Autocomplete(input);

      })(pac_input);


      
        var map, markerCluster;
        var markers = [];

        function initMap() {
          map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: 52.519325, lng: 13.392709},
            zoom : 5,
            mapTypeControl : false,
            scaleControl : false,
            streetViewControl : false,     
            fullscreenControl : false,
            maxZoom : 10,
            minZoom : 3,

            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT
            },
          });
          var input = document.getElementById('searchInput');
          var Autocomplete  = new google.maps.places.Autocomplete(input);
          map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(input);
          google.maps.event.addListenerOnce(map, "dragstart", function () { isDragging = true; });
          google.maps.event.addListenerOnce(map, "dragend", function () { isDragging = false; });
          //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          map.addListener('bounds_changed', function() {
            Autocomplete.setBounds(map.getBounds());
          });

          // Listen for the event fired when the user selects a prediction and retrieve
          // more details for that place.

          Autocomplete.addListener('place_changed', function() {
            var place = Autocomplete.getPlace();

            if (place.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
              marker.setMap(null);
            });

            markers = [];

            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            map.fitBounds(bounds);
          });

          var options = {
              styles: [
                  {
                      url: 'assets/images/marker/m1.png',
                      height: 42,
                      width: 25,
                      anchor: [5,0],
                  },
                  {
                      
                      url: 'assets/images/marker/m2.png',
                      height:42,
                      width: 25,
                      anchor:[5,0],
                  },
                  {
                      
                      url: 'assets/images/marker/m3.png',
                      height: 42,
                      width: 25,
                      anchor:[5,0],
                  },
                  {
                      
                      url: 'assets/images/marker/m4.png',
                      height: 42,
                      width: 25,
                      anchor:[5,0],
                  },
                  {
                      
                      url: 'assets/images/marker/m5.png',
                      height: 42,
                      width: 25,
                      anchor:[5,0],
                  }
              ],
              maxZoom: 10 
          };
          var maxZoom = 10;
          markerCluster = new MarkerClusterer(map, markers, options);
          ClusterIcon.prototype.triggerClusterClick = function ( e ) {
              var markerCluster = this.cluster_.getMarkerClusterer();
              google.maps.event.trigger( markerCluster, 'clusterclick', this.cluster_, e );
                  if (map.getZoom() === maxZoom) {
                      map.setCenter(this.cluster_.getCenter());
                      window.currentCluster = [];
                      for (var i = 0, len = this.cluster_.markers_.length; i < len; i++) {
                          window.currentCluster.push(this.cluster_.markers_[i].id);
                      }
                      $.ajax({
                         url: '/api/posts',
                         data: { locationIds: window.currentCluster} ,
                         type: 'POST',
                         success: function(data) {
                              var html = '';
                              $.each(data.data, function (i, item) {
                                  html += "<a href='/post/" + item['id'] + "' class='result'><div class='result-image'><img src='" + item['imageURL'] + "' /></div><div class='result-info'>";
                                  html += "<div class='image-cropper'><img src='" + item['user']['profilePictureURL'] + "' alt='User' /></div><h4>" + item['user']['display_name'] + "</h4><p>" + item['name'] + "</p></div></a>";
                              }); 
                              $('#searchResults').empty();
                              $('#searchResults').append(html);
                              var topDivHeight = $("#map").height();
                              var viewPortSize = $(window).height();
                              $('.main').animate({
                                  scrollTop: topDivHeight
                              }, 2000);
                         }
                      });
                  } else if ( markerCluster.isZoomOnClick() ) {
                      map.fitBounds( this.cluster_.getBounds() );
                  }               
          }
          getLocations();
        }

        function getLocations() {
          $.ajax({
             url: '/api/locations',
             dataType: 'json',
             success: function(data) {
              $.each(data.data, function(i, item) {
                      var icon = {
                          url: "assets/images/marker/m1.png", // url
                      };
                      var marker = new google.maps.Marker({
                          position: new google.maps.LatLng(item.lat, item.lng),
                          id: item.id,
                          map: map,
                          icon: icon,
                          label: {
                              fontWeight: "bold",
                              fontSize: "14px",
                          }
                      });
                      google.maps.event.addListener(marker, 'click', function(e) {
                          var latLng = e.latLng;
                          
                          $.ajax({
                             url: '/api/posts/location/',
                             data: { lat: latLng.lat(), lng : latLng.lng()} ,
                             type: 'POST',
                             success: function(data) {
                                  var html = '';
                                  $.each(data.data, function (i, item) {
                                      html += "<a href='/post/" + item['id'] + "' class='result'><div class='result-image'><img src='" + item['imageURL'] + "' /></div><div class='result-info'>";
                                      html += "<div class='image-cropper'><img src='" + item['user']['profilePictureURL'] + "' alt='User' /></div><h4>" + item['user']['display_name'] + "</h4><p>" + item['name'] + "</p></div></a>";
                                  }); 
                                  $('#searchResults').empty();
                                  $('#searchResults').append(html);
                                  var topDivHeight = $("#map").height();
                                  var viewPortSize = $(window).height();
                                  $('.main').animate({
                                      scrollTop: topDivHeight
                                  }, 2000);
                             }
                          });


                        });
                      markers.push(marker);
              });
              markerCluster.addMarkers(markers);
             }
          });
        }

          function initSearchInput() {
              var input = document.getElementById('searchInput');
              var autocomplete = new google.maps.places.Autocomplete(input);
              //google.maps.event.addDomListener(window, 'load', initialize);
          }

          $(document).ready(function () {
              initMap();
              //initSearchInput();
          });
      }
})();
