(function () {
    'use strict';
$(document).ready(function () {
    $('.headroom').each(function () {
      var $win = $(".main"),
        $self = $(this),
        isHidden = false,
        lastScrollTop = 0;

      $win.on('scroll', function () {
        var scrollTop = $win.scrollTop();
        var offset = scrollTop - lastScrollTop;
        lastScrollTop = scrollTop;

        // min-offset, min-scroll-top
        if (offset > 10 && scrollTop > 200 ) {
          if (!isHidden) {
            $self.addClass('header-hidden');
            isHidden = true;
          }
        } else if (offset < -10 || scrollTop < 200) {
          if (isHidden) {
            $self.removeClass('header-hidden');
            isHidden = false;
          }
        }
      });
    });

  });
})();