(function () {
    'use strict';
     /*$( '.login-fb' ).click(function(e) {
         FB.login(function(response) {
             if (response.authResponse) {

             }
         }, {scope: 'email, user_photos'});
         e.preventDefault();
     });*/
})();
