(function () {
    'use strict';
    $(document).ready(function () {

        function getComments($postId) {
            var $commentContainer = $('[data-comment-id="' + $postId + '"]');
            $.ajax({
               url: '/api/comments',
               data: { postId: $postId} ,
               type: 'POST',
               success: function(data) {
                    var $comments = $commentContainer.find(".comments");
                    $comments.empty();
                    var source   = $("#commentTemplate").html();
                    var template = Hogan.compile(source);
                    var output = template.render(data);
                    $comments.html(output);
                    $commentContainer.slideDown('slow'); 
                    $('.container').animate({
                        scrollTop: $('.container').scrollTop() + $('[data-comment-id="' + $postId + '"]')[0].scrollHeight
                    }, 2000);
               }
            });
        }

        $('.posts-container').on('click','a#showComments', function(e) {
            e.preventDefault();
            var $_this = $(this);
            var $postId = $_this.data("id");
            
            if(!$_this.parent().hasClass("active")) {
                console.log("comments open:", $postId);
                $_this.parent().addClass("active");
                   
                getComments($postId);

                } else {
                    var $commentContainer = $('[data-comment-id="' + $postId + '"]');
                    console.log("comments open:", $postId);
                    $_this.parent().removeClass("active");
                    $commentContainer.slideUp("slow"); 
                }
            });

            $('.posts-container').on('submit','#comment-post', function(e){
                console.log("posting...");
                e.preventDefault();
                var $_this = this;
                var $formdata = $(this).serialize();
                var $postId = $('input[name=postid]', this).val();
                $.ajax({
                    headers: {
                        'Authorization':'Bearer ' + $('input[name=api_token]').val(),
                    },
                    url: '/api/comment/post',
                    data: $formdata,
                    type: 'POST',
                    success: function(data) {
                        $_this.reset();
                        console.log("success", data);
                        console.log("Post Id", $postId);
                        getComments($postId);
                    },
                    error: function(data) {
                        window.setTimeout(function () {
                            location.href = "/login";
                        }, 3000);
                    }
                });
            });

    });

})();
