(function() {
    'use strict';
    $(document).ready(function () {
        function GetCookie(name) {
            var arg=name+"=";
            var alen=arg.length;
            var clen=document.cookie.length;
            var i=0;

            while (i<clen) {
                var j=i+alen;
                    if (document.cookie.substring(i,j)==arg)
                        return "here";
                    i=document.cookie.indexOf(" ",i)+1;
                    if (i==0) 
                        break;
            }

            return null;
        }
        var visit = GetCookie("showed_note");
        if (visit == null) {
            $("#copyrightOverlay").show();
            document.cookie = "showed_note=true";
        } else {
            $("#copyrightOverlay").hide();
        }

        $('#copyrightOverlay #closeOverlayBtn').on('click', function(e) {
            $("#copyrightOverlay").hide();
        });
    });

})();