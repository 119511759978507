(function () {
    'use strict';
    if($('body').is('.view-profile')){
        $(document).ready(function () {
            $('.btn-view-galerie').on('click', function(e) {
                e.preventDefault();
                if($('body').hasClass('view-list')) {
                    $('body').removeClass("view-list").addClass("view-galerie");
                    $('.btn-view-list').removeClass("active");
                    $(this).addClass("active");
                }
            });

            $('.btn-view-list').on('click', function(e) {
                e.preventDefault();
                if($('body').hasClass('view-galerie')) {
                    $('body').removeClass("view-galerie").addClass("view-list");
                    $('.btn-view-galerie').removeClass("active");
                    $(this).addClass("active");
                }
            });
            $('.posts-container .post').each(function() {
                $(this).on('click', function(e) {
                if($('body').hasClass('view-galerie')) {    
                    var _this = this;
                    console.log("1");
                    $('body').removeClass("view-galerie").addClass("view-list");
                    $('.btn-view-galerie').removeClass("active");
                    $('.btn-view-list').addClass("active");

                    var topDivHeight = $("#map").height();
                    var viewPortSize = $(window).height();
                    $('.container').animate({
                        scrollTop: $(_this).offset().top - 115
                    }, 2000);  
                }
                });
            });
        });
    }

    if($('body').is('.view-profile-upload')){
        $('#gallery-upload, #camera-upload').on('change', function(e) {
            $(this).closest('form').submit();
        });
    }
})();
