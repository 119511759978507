(function () {
    'use strict';
    $(document).ready(function () {

        $( '#post-menu li:has(ul)' ).doubleTapToGo();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $( '.posts-container' ).on('click','.post .share > a', function(e) {
            e.preventDefault();
            var _this = this;
            if($(_this).parent().hasClass('active')) {
                $(_this).parent().removeClass('active');
            } else {
                $(_this).parent().addClass('active');
            }
        });

        $('.posts-container').on('click',' #favBtn', function(e) {
            e.preventDefault();
            var _this = this;
            $.ajax({
                url: "/like/toggle",
                type: "post",
                data: {
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                    'post_id': $(_this).data("post"),
                    'user_id': $(_this).data("user")
                },
                success: function(data){
                    $('.like-count[data-like-id="' + $(_this).data("post") + '"] span').html(data['likeCount']);
                    if($(_this).parent().hasClass('active')) {
                        $(_this).parent().removeClass('active');
                    } else {
                        $(_this).parent().addClass('active');
                    }    
                },
                error: function(data) {
                        window.setTimeout(function () {
                            location.href = "/login";
                        }, 3000);
                    }
            });
        });

        if($('body').is('.view-home')){
            var infinitScroll = function (options) {
                var count = options.count ? options.count : 1;
                var didGetData = true;
                $('.container').on('scroll', function () {
                    if ($('.container').scrollTop() >= $('.posts-container').height() - $('.container').height() + 110) {
                        loadMore();
                        count++;
                        console.log(count);
                    }
                }).scroll();

                function loadMore() {
                    var url = "/posts/?page=" + count;
                    if (didGetData) {
                        $.ajax({
                            type: "GET",
                            url: url,
                            success: function (response) {
                                if (response) {
                                    didGetData = true;
                                    $('.posts-container').append(response);
                                } else {
                                    didGetData = false;
                                }
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                console.log(errorThrown);
                                console.log(textStatus);
                            }
                        });
                    }
                }
            }
            infinitScroll({count: 1});
        }
    });



})();